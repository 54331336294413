import { withStyles } from '@material-ui/core/styles'
import queries from '../../mediaqueries'

import { drawerWidth } from '../constants'

const styles = theme => ({
    root: {
      display: "flex"
    },
    hide: {
      display: "none"
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: "64px"
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20
    },
    navBtns: {
      marginRight: "1rem",
      "& a": {
        textDecoration: "none"
      },
      [queries.down('xs')]: {
          marginRight: '.5rem'
      }
    },
    button: {
      margin: "0 0.5rem",
      [queries.down('xs')]: {
          padding: '.3rem',
          margin: '0 .2rem'
      }
    }
})

export default withStyles(styles, {withTheme: true })